<template>
  <div class="edit">
    <div v-if="active === 1" class="p-lr-20 p-top-10">
      <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 申请信息</div>
      <el-form ref="ruleForm" label-width="110px" label-position="left" :inline="true" :rules="rules" :model="form" size="mini" class="demo-form-inline">
        <div class="InfoList3">
          <el-form-item label="申请日期：" prop="ApplyDate">
            <template>
              {{ form.ApplyDate }}
            </template>
          </el-form-item>
          <el-form-item label="申请部门：" prop="ApplyDepartmentID">
            <el-select v-model.number="form.ApplyDepartmentID" placeholder="申请部门" @change="isBumen">
              <el-option v-for="item in PermissionGroup" :key="item.IDX" :label="item.GroupName" :value="item.IDX" />
            </el-select>
          </el-form-item>
          <el-form-item label="申请人：">
            <el-select v-model.number="form.ApplyUserID" v-el-select-loadmore="loadmore" placeholder="申请人">
              <el-option v-for="i in ApplyUserGroup" :key="i.UserID" :label="i.NickName" :value="i.UserID" />
            </el-select>
          </el-form-item>
          <el-form-item label="填表人：">
            <el-select v-model.number="userInfo.NickName" disabled placeholder="填表人">
              <el-option label="小芳" :value="1" />
              <el-option label="小王" :value="2" />
            </el-select>
          </el-form-item>
        </div>
        <div class=" InfoList4">
          <div class="flex a-center">
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 企业基本信息
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="客户" />
                <el-checkbox label="供应商" />
              </el-checkbox-group>
              <span style="color:#F56C6C" class="p-lr-10"> *</span>

              <el-popover
                placement="right-end"
                width="200"
                trigger="hover"
              >
                <span>
                  如果即是客户又是供应商的角色，请勾选两项
                </span>
                <i slot="reference" class="el-icon-info" style="color:#ccc" />
              </el-popover>
            </div>
          </div>
          <el-form-item label="企业名称：" prop="CompanyName">
            <el-input
              v-model="form.CompanyName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="信用代码：" prop="CreditCode">
            <el-input
              v-model="form.CreditCode"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="企业简称：" prop="date">
            <el-input
              v-model="form.CompanyAbbreviation"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="企业编号：" prop="date">
            <el-input
              v-model="form.CompanyNo"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="联系人：" prop="date">
            <el-input
              v-model="form.ContactName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="联系电话：" prop="date">
            <el-input
              v-model="form.ContactTel"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="传真：" prop="date">
            <el-input
              v-model="form.Fax"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="邮箱：" prop="date">
            <el-input
              v-model="form.Email"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="法人姓名：" prop="date">
            <el-input
              v-model="form.CorporationName"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="法人电话：" prop="date">
            <el-input
              v-model="form.CorporationTel"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="被授权人：" prop="date">
            <el-input
              v-model="form.AuthorizedPerson"
              size="mini"
              placeholder="请输入内容"
            />
          </el-form-item>
        </div>
        <div class="InfoBoxTwo2">
          <el-form-item label="详细地址：" prop="Province" size="mini">
            <div class="flex">
              <el-cascader
                v-model="selectedOptions"
                size="mini"
                :options="options1"
                style="width:290px"
                @change="handleChange"
              />
              <el-input
                v-model="form.Street"
                size="mini"
                style="margin-left:10px"
                placeholder="请输入内容"
              />
            </div>
          </el-form-item>
        </div>
        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
        <div class="InfoList4">
          <el-form-item label="开户银行：" size="mini">
            <el-select v-model="form.Bank" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="w_66" label="开户支行：" size="mini">
            <div class="flex">
              <el-cascader
                v-model="selectedOptions1"
                size="mini"
                :options="options1"
                @change="handleChange1"
              />
              <el-select v-model="form.BranchBank" placeholder="支行" class="m-left-10">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </el-form-item>
        </div>
        <div class="InfoList4">
          <el-form-item label="付款方式：" size="mini">
            <el-select
              v-model="form.PayType"
              placeholder="请选择"
            >
              <el-option label="货到付款" value="货到付款" />
              <el-option label="先款后货" value="先款后货" />
              <el-option label="月结" value="月结" />
              <el-option label="先付定金，货到付余款" value="先付定金，货到付余款" />
              <el-option label="其他" value="其他" />
            </el-select>
            <!-- <el-input
              v-model.number="form.PayType"
              size="mini"
              placeholder="请输入内容"
            /> -->
          </el-form-item>
          <el-form-item label="回款账期：" size="mini" prop="PaymentCollectionDays">
            <el-input v-model="form.PaymentCollectionDays" type="number" />
          </el-form-item>
          <el-form-item label="开票税率：" size="mini" prop="TaxRate">
            <el-select
              v-model="form.TaxRate"
              placeholder="请选择"
            >
              <el-option label="17%" :value="0.17" />
              <el-option label="16%" :value="0.16" />
              <el-option label="13%" :value="0.13" />
              <el-option label="11%" :value="0.11" />
              <el-option label="10%" :value="0.10" />
              <el-option label="6%" :value="0.06" />
              <el-option label="3%" :value="0.03" />
              <el-option label="1%" :value="0.01" />
              <el-option label="0%" :value="1" />
              <el-option label="   " :value="0" disabled />
            </el-select>

            <!-- <el-input
              v-model.number="form.TaxRate"
              style="width:100px"
              size="mini"
              placeholder=""
              type="number"
            />  %-->
          </el-form-item>

        </div>
        <div class="InfoList4">
          <el-form-item label="备注：" size="mini" prop="date">
            <el-input
              v-model="form.Remark"
              size="mini"
              placeholder=""
            />
          </el-form-item>
        </div>
      </el-form>
      <div class="caozuo t-right t_line_s p-top-20">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancle">取消</el-button>
      </div>
    </div>
    <div v-if="active === 2" class="p-tb-10 main_set">
      <div
        :class="
          isMore
            ? 'p-lr-20 animation'
            : 'p-lr-20 animation'
        "
      >
        <el-form
          :inline="true"
          label-position="left"
          :model="formInline"
          size="mini"
          label-width="80px"
          class="demo-form-inline"
        >
          <div>
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" />申请信息</div>

            <el-form-item label="申请日期" class="eachitem">
              {{ info.ApplyDateString }}
            </el-form-item>
            <el-form-item label="申请部门" class="eachitem">
              {{ info.ApplyDepartment }}
            </el-form-item>
            <el-form-item label="申请人" class="eachitem">
              {{ info.ApplyUser }}
            </el-form-item>
            <el-form-item label="填表人" class="eachitem">
              {{ info.FillFormUser }}
            </el-form-item>
          </div>
          <div>
            <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" />企业基本信息</div>
            <el-form-item label="企业名称" class="eachitem">
              {{ info.CompanyName }}
            </el-form-item>
            <el-form-item label="企业简称" class="eachitem">
              {{ info.CompanyAbbreviation }}
            </el-form-item>
            <el-form-item label="企业编号" class="eachitem">
              {{ info.CompanyNo }}
            </el-form-item>
            <el-form-item label="企业类型" class="eachitem">
              {{ CompanyTypeformatter({ CompanyType: info.CompanyType }) }}
            </el-form-item>
          </div>
          <div>
            <el-form-item label="联系人" class="eachitem">
              {{ info.ContactName }}
            </el-form-item>
            <el-form-item label="联系电话" class="eachitem">
              {{ info.ContactTel }}
            </el-form-item>
            <el-form-item label="传真" class="eachitem">
              {{ info.Fax }}
            </el-form-item>
            <el-form-item label="邮箱" class="eachitem">
              {{ info.Email }}
            </el-form-item>
          </div>
          <div>
            <el-form-item label="企业法人" class="eachitem">
              {{ info.CorporationName }}
            </el-form-item>
            <el-form-item label="法人电话" class="eachitem">
              {{ info.CorporationTel }}
            </el-form-item>
            <el-form-item label="信用代码" class="eachitem">
              {{ info.CreditCode }}
            </el-form-item>
            <el-form-item label="被授权人" class="eachitem">
              {{ info.AuthorizedPerson }}
            </el-form-item>
          </div>
          <el-form-item label="详细地址" class="eachitem">
            {{ info.Province }}{{ info.City }}{{ info.Area }}{{ info.Street }}
          </el-form-item>
          <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" />财务信息</div>
          <div>
            <el-form-item label="开户银行" class="eachitem">
              {{ info.Bank }}
            </el-form-item>
            <el-form-item label="开户支行" class="eachitem">
              {{ info.BranchBank }}
            </el-form-item>
            <el-form-item label="付款方式" class="eachitem">
              {{ info.PayType }}
            </el-form-item>
            <el-form-item label="回款账期" class="eachitem">
              {{ info.PaymentCollectionDays }}
            </el-form-item>
            <el-form-item label="开票税率" class="eachitem">
              {{ info.TaxRate }}%
            </el-form-item>
            <el-form-item label="备注：" class="eachitem">
              {{ info.Remark }}
            </el-form-item>
          </div>
        </el-form>
        <div v-if="!isMore" class="more_c cursor" style="right:50px" @click="() => (isMore = true)">更多</div>
        <div v-if="!isMore" class="more_c cursor p-right-10" style="right: 0px; color: #000" @click="setItem()">修改</div>
        <div v-if="isMore" class="up flex j-center">
          <i class="el-icon-arrow-up" @click="() => (isMore = false)" />
        </div>
      </div>
      <PageHeader title="资质文件">
        <el-button size="mini" type="danger" plain @click="delImgs(1, false)">批量删除</el-button>
        <el-button size="mini" @click="uoLoad">上传</el-button>
        <!-- <el-button
          size="mini"
          type="primary"
          :loading="btnLoadiung"
          @click="LaunchApproval"
        >{{ view_item.Status === 3 ? "重新发起" : "发起审核" }}</el-button> -->
      </PageHeader>
      <div class="main_flex flex j-between">
        <div class="left min-border-right a-center p-tb-20">
          <!-- <el-upload
              class="upload-demo"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              multiple
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload> -->
          <div
            v-for="(i, index) in tableData1"
            :key="index"
            style="position: relative"
            class="img_view m-tb-20"
          >
            <img :src="imgsUrl + i.PhotoUrl" @click="showPic">
            <div
              style="position: absolute; right: 5px; top: 5px; z-index: 10"
              class="cursor"
              @click="delImgs(i, true)"
            >
              x
            </div>
          </div>
        </div>
        <div class="right">
          <PageHeader :border="false" title="已上传的文件" />
          <div class="p-lr-20">
            <el-table
              ref="multipleTable"
              :border="false"
              :data="tableData1"
              height="400px"
              tooltip-effect="dark"
              @select-all="selectAll1"
              @select="select1"
            >
              <el-table-column type="selection" width="42" />
              <el-table-column
                prop="TypeString"
                label="证件类型"
                width="180"
              />
              <el-table-column
                prop="CompanyName"
                label="企业名称"
                width="180"
              />
              <el-table-column prop="CertificateCode" label="证件编号" />
              <el-table-column prop="ValidToString" label="证件有效期" width="100" />
              <el-table-column prop="Status" label="状态" />
              <el-table-column prop="address" label="操作" fixed="right" width="50">
                <template slot-scope="scope">
                  <el-popover
                    placement="left"
                    trigger="hover"
                    :width="50"
                    popper-class="test_pop_view"
                  >
                    <div class="DropDownList">
                      <div class="OperationButton">
                        <el-link @click="editImgs(scope.row)"><i class="el-icon-edit p-right-10" />编辑</el-link>
                      </div>
                      <div class="OperationButton">
                        <el-link @click="delImgs(scope.row, true)"><i class="el-icon-delete p-right-10" />删除</el-link>
                      </div>
                      <div class="OperationButton">
                        <el-link @click="downLoadImgs(scope.row)"><i class="el-icon-download p-right-10" />下载</el-link>
                      </div>
                    </div>
                    <i slot="reference" class="el-icon-more" />
                  </el-popover>
                </template>
              </el-table-column>
              <div slot="empty">
                <!-- <div class="p-top-20">
                    <img src="../../assets/logo.png" alt="" width="140" height="140">
                  </div> -->
                <p :style="{ marginTop: '10px' }">表格数据为空</p>
              </div>
            </el-table>
          </div>
          <!-- <Foot /> -->
        </div>
      </div>
    </div>
    <Uploads
      ref="Uploads"
      :idx="IDX"
      :form="infow"
      :first-camp-company-i-d="firstCampCompanyID"
      :types="types"
      type="keshang"
      :show="dialogTableVisible1"
      :supplier-i-d="info.SupplierID"
      @cancel="cancels"
    />
    <el-image-viewer
      v-if="showViewer"
      style="z-index: 9999"
      :url-list="MaterialFileList"
      :on-close="() => (showViewer = fasle)"
    />
  </div>
</template>
<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import $api from '@/request/productApis.js'
import Uploads from '@/components/uploads'
import PageHeader from '@/components/PageHeader'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'Edit1',
  components: { Uploads, PageHeader, ElImageViewer },
  directives: {
    'el-select-loadmore': {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      imgsUrl: this.b2bstaticUrl,
      view_item: {},
      rules: {
        // ApplyDate: [
        //   { required: true, message: '请选择申请日期', trigger: 'blur' }
        // ],
        CompanyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        CompanyType: [
          { required: true, validator: (rule, value, callback) => {
            if (this.form.CompanyType === 0) {
              callback(new Error('请选择企业类型'))
            }
            callback()
          }, trigger: 'blur' }
        ],
        CreditCode: [
          { required: true, validator: (rule, value, callback) => {
            if (!this.form.CreditCode) {
              callback(new Error('请输入社会信用统一代码'))
            }
            if (this.form.CreditCode) {
              if (this.form.CreditCode.length !== 18) callback(new Error('请输入18位社会信用统一代码'))
              // const regNumber = /\d+/ // 验证0-9的任意数字最少出现1次。
              // const regString = /[a-zA-Z]+/ // 验证大小写26个字母任意字母最少出现1次。
              const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
              // 验证第一个字符串
              if (reg.test(this.form.CreditCode)) {
                console.log('str1：验证成功')
              } else {
                callback(new Error('请输入正确的社会信用统一代码'))
              }
            }
            callback()
          }, trigger: 'blur' }
        ],
        // PaymentCollectionDays: [
        //   { required: true, message: '请输入回款账期', trigger: 'blur' }
        // ],
        // ApplyDepartmentID: [
        //   { required: true, message: '请输入申请部门', trigger: 'blur' }
        // ],
        // TaxRate: [
        //   { required: true, message: '请输入开票税率', trigger: 'blur' }
        // ],
        Province: [
          { required: true, validator: (rule, value, callback) => {
            if (this.form.Province === '' || this.form.City === '' || this.form.Area === '' || this.form.Street === '') {
              callback(new Error('请输入详细地址'))
            }
            callback()
          }, trigger: 'blur' }
        ]
      },
      Area: [],
      selectedOptions: [],
      selectedOptions1: [],
      options: [],
      options1: regionData,
      checkList: [],
      form: {
        IDX: 0,
        ApplyDate: this.$minCommon.formatDate(new Date(), 'yyyy/MM/dd'),
        ApplyDepartmentID: '',
        ApplyUserID: '',
        CompanyType: 1,
        CompanyName: '',
        CreditCode: '',
        CompanyAbbreviation: '',
        CompanyNo: '',
        ContactName: '',
        ContactTel: '',
        Fax: '',
        Email: '',
        CorporationName: '',
        CorporationTel: '',
        AuthorizedPerson: '',
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Bank: '',
        BranchBankProvince: '',
        BranchBankCity: '',
        BranchBankArea: '',
        BranchBank: '',
        PayType: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        Remark: ''

      },
      PermissionGroup: [],
      ApplyUserGroup: [],
      ApplyUserPage: 1,
      ApplyUserPageSize: 15,
      userInfo: {},
      info: {},
      tableData1: [],
      active: 1,
      isMore: false,
      formInline: {
        user: '',
        region: ''
      },
      ImgsListSecltStr: '',
      ImgsListSeclt: [],
      types: [],
      IDX: '',
      infow: {},
      dialogTableVisible1: false,
      MaterialFileList: [],
      firstCampCompanyID: null,
      showViewer: false
    }
  },
  watch: {
    checkList(a) {
      console.log(a)
      if (a.indexOf('客户') !== -1 && a.indexOf('供应商') !== -1) {
        this.form.CompanyType = 4
      }
      if (a.indexOf('客户') !== -1 && a.length === 1) {
        this.form.CompanyType = 1
      }
      if (a.indexOf('供应商') !== -1 && a.length === 1) {
        this.form.CompanyType = 2
      }
      if (a.length === 0) {
        this.form.CompanyType = 0
      }
    },
    ImgsListSeclt(a) {
      this.ImgsListSecltStr = ''
      if (a.length === 0) return this.ImgsListSecltStr = ''
      a.map(item => {
        this.ImgsListSecltStr += item.IDX + ','
      })
    }
  },
  mounted() {
    // 预警证照跳转
    if (this.$route.params.IDX && this.$route.params.type === 1) {
      this.active = 2
      this.$api.GetCompanySupplierInfo({
        IDX: this.$route.params.IDX
      }).then(res1 => {
        console.log('获取企业信息', res1)
        this.info = res1.Message
        this.active = 2
        console.log('this.info.SupplierID', this.info.SupplierID)
        this.$api.GetCompanySupplierCertList({
          SupplierID: this.info.SupplierID
        }).then(res => {
          console.log(res)
          this.tableData1 = res.Message
        })
      })
      return
    }

    console.log(this.$route.params)
    this.IDX = this.$route.params.IDX
    // this.checkList.push('客户')
    this.$api.GetCompanySupplierInfo({
      IDX: this.$route.params.IDX
    }).then(res => {
      this.form = res.Message
      // this.form.TaxRate = this.form.TaxRate * 100
      console.log(this.form)
      this.setType(this.form.CompanyType)
      const Province = this.form.Province
      const City = this.form.City
      const Area = this.form.Area
      this.form.Province = TextToCode[Province].code
      this.form.City = TextToCode[Province][City].code
      this.form.Area = TextToCode[Province][City][Area].code
      this.selectedOptions = [this.form.Province, this.form.City, this.form.Area]

      const Province1 = this.form.BranchBankProvince
      const City1 = this.form.BranchBankCity
      const Area1 = this.form.BranchBankArea
      this.form.BranchBankProvince = TextToCode[Province1].code
      this.form.BranchBankCity = TextToCode[Province1][City1].code
      this.form.BranchBankArea = TextToCode[Province1][City1][Area1].code
      this.selectedOptions1 = [this.form.BranchBankProvince, this.form.BranchBankCity, this.form.BranchBankArea]
    })
    // this.GetApplyUserInfo().then(res => {
    //   console.log('申请部门', res)
    //   this.PermissionGroup = res.Message
    // })
    this.$api.PermissionGroupInfo().then(res => {
      console.log('申请部门', res)
      this.PermissionGroup = res.Message
      this.getUserInfo()
    })
  },
  methods: {
    // 设置修改默认的显示
    setType(type) {
      if (type === 4) {
        return this.checkList = ['客户', '供应商']
      }
      if (type === 1) {
        return this.checkList = ['客户']
      }
      if (type === 2) {
        return this.checkList = ['供应商']
      }
    },
    downLoadImgs(row) {
      console.log(row.PhotoUrl.split('.')[1])
      this.$api.GetFirstCampFiles({
        FileURL: this.b2bstaticUrl + row.PhotoUrl
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.' + row.PhotoUrl.split('.')[1])
      })
    },
    // 展示图片预览
    showPic() {
      this.MaterialFileList = []
      this.showViewer = true
      this.tableData1.forEach(item => {
        this.MaterialFileList.push(`${this.imgsUrl}${item.PhotoUrl} `)
      })
      // this.MaterialFileList.push(src)
    },
    showImage(src) {
      console.log(src)
      this.MaterialFileList = []
      this.showViewer = true
      this.MaterialFileList.push(src)
    },
    selectAll1(e) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(e)
    },
    select1(row) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(row)
    },
    uoLoad() {
      this.dialogTableVisible1 = true
      const IDX = this.infow.IDX
      this.infow = {}
      this.IDX = ''
      this.infow.IDX = IDX
      this.GetCertificateType()
    },
    GetCertificateType() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },
    cancels(e) {
      this.dialogTableVisible1 = e
      this.$api.GetCompanySupplierCertList({
        SupplierID: this.info.SupplierID
      }).then(res => {
        console.log(res)
        this.tableData1 = res.Message
      })
      // this.$api.GetFirstCampCompanyCertificateList({
      //   firstCampCompanyID: this.firstCampCompanyID
      // }).then(res => {
      //   console.log(res)
      //   this.tableData1 = res.Message
      // })
    },
    editImgs(row) {
      this.IDX = row.IDX + ''
      this.dialogTableVisible1 = true
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        this.types = res.Message
      })
      this.$api.GetFirstCampCertificateInfo({
        IDX: this.IDX
      }).then(res => {
        console.log(res)
        this.infow = res.Message
        this.infow.IsLongRange === 1 ? this.infow.IsLongRange = true : this.infow.IsLongRange = false
        this.infow.IsLongRange ? this.infow.ValidTo = '' : ''
        this.$refs.Uploads.option.img = `${this.b2bstaticUrl}${this.infow.PhotoUrl}`
      })
    },
    delImgs(row, isAll) {
      console.log(row)
      if (!isAll && this.ImgsListSecltStr === '') return this.$message.error('没有进行任何勾选')
      this.$confirm('确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteFirstCampCompanyCert({
          IDXList: isAll ? row.IDX + '' : this.ImgsListSecltStr
        }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.$api.GetFirstCampCompanyCertificateList({
              firstCampCompanyID: this.firstCampCompanyID
            }).then(res => {
              this.tableData1 = res.Message
            })
          }
        })
      })
    },
    setItem() {
      console.log(this.IDX)
      this.active = 1
    },
    // 获取当前信息
    getUserInfo() {
      $api.getUserInfo().then(res => {
        console.log('dsaasdasd', res)
        this.userInfo = res.Message
        this.form.ApplyDepartmentID = res.Message.PermissionGroupID * 1 // 申请部门
        this.GetApplyUserInfo(this.form.ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize).then(response => {
          this.ApplyUserGroup = response.Message
        })
        this.form.ApplyUserID = res.Message.UserID // 申请人
        this.form.FillFormUserID = res.Message.UserID // 填表人
      })
    },
    // this.$api.PermissionGroupInfo().then(res => {
    //     console.log('申请部门', res)
    //     this.PermissionGroup = res.Message
    //     this.getUserInfo()
    //   })
    // 申请部门
    async   GetApplyUserInfo(permissionGroupInfoId, pageIndex, pageSize) {
      return await this.$api.GetApplyUserInfo({
        permissionGroupInfoId, pageIndex, pageSize
      })
    },
    isBumen(e) {
      this.form.ApplyUserID = ''
      this.GetApplyUserInfo(this.form.ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize).then(response => {
        this.ApplyUserGroup = response.Message
      })
    },
    handleChange(value) {
      this.form.Province = CodeToText[value[0]]
      this.form.City = CodeToText[value[1]]
      this.form.Area = CodeToText[value[2]]
    },
    handleChange1(value) {
      this.form.BranchBankProvince = CodeToText[value[0]]
      this.form.BranchBankCity = CodeToText[value[1]]
      this.form.BranchBankArea = CodeToText[value[2]]
    },
    CompanyTypeformatter(row, column) {
      return row.CompanyType === 1 ? '客户' : row.CompanyType === 2 ? '供应商' : '客户/供应商'
    },
    // 加载更多
    loadmore() {
      // console.log(e)
      // this.ApplyUserPage++
      this.ApplyUserPage++
      this.GetApplyUserInfo(this.form.ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize).then(response => {
        this.ApplyUserGroup = this.ApplyUserGroup.concat(response.Message)
        console.log(response)
      })
    },
    cancle() {
      this.$router.go(-1)
    },
    save() {
      console.log('this.params', this.$route.params)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // this.form.TaxRate = this.form.TaxRate * 1
          // this.form.PaymentCollectionDays = this.form.PaymentCollectionDays * 1
          // this.form.PayType = 0
          //   this.form.IDX = this.IDX || ''
          // if (!this.form.IDX) {
          //   this.form.Province = CodeToText[this.form.Province[0]]
          //   this.form.City = CodeToText[this.form.City[0]]
          //   this.form.Area = CodeToText[this.form.Area[0]]

          //   // this.form.BranchBankProvince = CodeToText[this.form.BranchBankProvince[0]]
          //   // this.form.BranchBankCity = CodeToText[this.form.BranchBankCity[0]]
          //   // this.form.BranchBankArea = CodeToText[this.form.BranchBankArea[0]]
          // }
          // if (this.form.IDX) {
          //   this.form.Province = CodeToText[this.form.Province]
          //   this.form.City = CodeToText[this.form.City]
          //   this.form.Area = CodeToText[this.form.Area]
          //   // this.form.BranchBankProvince = CodeToText[this.form.BranchBankProvince]
          //   // this.form.BranchBankCity = CodeToText[this.form.BranchBankCity]
          //   // this.form.BranchBankArea = CodeToText[this.form.BranchBankArea]
          // }
          console.log(this.form)
          // this.form.SourceType = 2 // 客商
          // this.form.CompanyID = this.$route.params.IDX // SaveCompanyCertificate
          this.$api.SaveCompanySupplier(this.form).then(res => {
            console.log('保存客商', res)
            this.firstCampCompanyID = res.Message // 企业ID 没有返回信息
            if (res.RetCode === '0') {
              // this.form = {}
              this.$confirm('保存成功', '提示', {
                confirmButtonText: '上传资质',
                cancelButtonText: '返回列表',
                type: 'success',
                center: true
              }).then(() => {
                this.$api.GetCompanySupplierInfo({
                  IDX: this.$route.params.IDX
                }).then(res1 => {
                  console.log('获取企业信息', res1)
                  this.info = res1.Message
                  this.active = 2
                  console.log('this.info.SupplierID', this.info.SupplierID)
                  this.$api.GetCompanySupplierCertList({
                    SupplierID: this.info.SupplierID
                  }).then(res => {
                    console.log(res)
                    this.tableData1 = res.Message
                  })
                })
              }).catch(() => {
                this.$router.go(-1)
              })
              // this.$confirm('保存成功', '提示', {
              //   distinguishCancelAndClose: false,
              //   confirmButtonText: '返回列表',
              //   type: 'warning'
              // }).then(() => {
              //   // console.log(this.refreshCurrentChange())
              //   this.$router.go(-1)
              //   this.$router.push({
              //     name: 'customerList'
              //   })
              // })
              return
            }
            this.$message.error(res.RetMsg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
<style scoped lang='scss'>
@import '@/style/product.scss';
.more_c {
  position: absolute;
  right: 20px;
  top: 20px;
}
.main_set{
   position: relative;
}
.info_c {
  height: 230px;
  position: relative;
  overflow: hidden;
  // background-color: red;
  transition: all 2s ease;
  .more {
    position: absolute;
    right: 50px;
    top: 20px;
    color: #096dcd;
  }
}
.info_c_auto {
  position: relative;
   height: auto;
  max-height: 470px;
  transition: all 2s;
  .up {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }
}
  .main_flex {
    // width: 100%;
    min-height: 400px;
    .left {
      width: 400px;
      height: 400px;
      overflow: auto;
      flex-direction: column;
      .img_view {
        width: 200px;
        height: 200px;
        background-color: #fff;
        border: 1px solid #ccc;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .close {
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }
    }
    .left::-webkit-scrollbar {
      display: none;
    }
    .right {
      flex: 1;
      min-width: 400px;
    }
  }
</style>
